import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import axios from "axios";
import store from "./store";
import utilsMix from "./mixins/utilsMix";
import Notifications from "vue-notification";
import jwt_decode from "jwt-decode";
import { ObserveVisibility } from "vue-observe-visibility";
import VueThreeSixty from "vue-360";
import VueKonva from "vue-konva";
import "vue-360/dist/css/style.css";
import "./assets/style/style.scss";
import { getPermissonsUser } from "./components/service/api";
import VueMasonry from "vue-masonry-css";

Vue.use(VueThreeSixty);
Vue.use(VueKonva);
Vue.use(VueMasonry);

Vue.mixin(utilsMix);
Vue.use(Notifications);

Vue.directive("observe-visibility", ObserveVisibility);

const axiosInstance = axios;

let initRoute = window.location.href.substring(window.location.host.length + 8);
let pushAfterInit = initRoute;

axiosInstance.interceptors.request.use(
  function (config) {
    // перехватчик запросов. Добавляет заголовок авторизации
    const whiteList = [
      "/config.json",
      "/token",
      "/registration",
      "reset",
      "password_reset",
      "confirm",
      "https://cdn-",
      "/resend",
      "/api/v1/products/?tags=welcome",
      "/api/v1/widgets/default/login",
      "-oauth2",
      "www.googleapis.com/youtube/v3/playlistItems?",
      "social/complete/",
      "/tips.json",
    ]; // список запросов для которых не нужен токен
    let inWhiteList = false;
    whiteList.forEach((urlFromWhite) => {
      if (~config.url.indexOf(urlFromWhite)) {
        inWhiteList = true;
      }
    });
    if (inWhiteList) {
      return config;
    }
    const accessToken = localStorage.getItem("zarboAccessToken");
    if (accessToken) {
      config.headers.Authorization = "Bearer " + accessToken;
    } else {
      router.push("/login");
      throw new axios.Cancel("Cancel request. No access token " + JSON.stringify(config));
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        if (router.history.current.name !== "login") {
          console.log("Статус запроса 401");
          router.push("/login");
        }
      }
      if (error.response.status === 403) {
        const result = store.state.appStore.allTeams.some(
          (team) => team.team.id === store.state.appStore.selectedTeam.team.id
        );
        if (!result) {
          const myTeam = store.state.appStore.myTeam;
          eventBus.$emit("changeTeam", myTeam);
        }
      }
    }
    return Promise.reject(error);
  }
);

async function getConfig() {
  const url = location.origin + "/config.json";
  let config = null;
  try {
    config = await axios.get(url);
  } catch (error) {
    throw new Error("Fail get config", error);
  }
  return config.data;
}

async function getTips() {
  const url = location.origin + "/tips.json";
  let tips = null;
  try {
    tips = await axios.get(url);
  } catch (error) {
    throw new Error("Fail get tips", error);
  }
  return tips.data;
}

async function getTeams(config) {
  if (initRoute.includes('/confirm/')) { // при подтверждении email запрашиваются команды и редирект на логин вместо подтверждения почты
    return
  }
  const url = config.BASE_URL + "/api/v1/teams/";
  try {
    const resp = await axios.get(url);
    store.dispatch("SET_VALUE", { key: "allTeams", value: resp.data.results });
    resp.data.results.forEach((team) => {
      if (team.role.codename === "owner") {
        store.dispatch("SET_VALUE", {
          key: "myTeam",
          value: team,
        });
      }
    });
  } catch (error) {
    console.error("Fail get teams", error);
  }
}

async function checkAuth(config) {
  const refreshToken = localStorage.getItem("zarboRefreshToken");
  const url = config.BASE_URL + "/api/token/refresh/";
  if (
    initRoute.split("/")[1] === "password_reset" ||
    initRoute.split("/")[1] === "confirm" ||
    initRoute.split("/")[1] === "social" ||
    initRoute.split("/")[1] === "oauth"
  ) {
    return;
  }

  if (!refreshToken || refreshToken === "null" || refreshToken === null) {
    pushAfterInit = "/login";
    return;
  }
  try {
    const resp = await axios.post(url, { refresh: refreshToken });
    localStorage.setItem("zarboAccessToken", resp.data.access);
    store.dispatch("SET_VALUE", { key: "isAuthorized", value: true });
    let decoded = jwt_decode(resp.data.access);
    await getUserInfo(config, decoded.user_id);
    // Запрашиваем Разрешения
    let result;
    if (localStorage.getItem("selectedTeam")) {
      let teamId = null;
      const selectedTeamId = Number(localStorage.getItem("selectedTeam"));

      const resultTeams = store.state.appStore.allTeams.some(
        (team) => team.team.id === selectedTeamId
      );

      if (!resultTeams) {
        const myTeam = store.state.appStore.myTeam;
        store.dispatch("SET_VALUE", { key: "selectedTeam", value: myTeam });
        teamId = myTeam.team.id;
        localStorage.setItem("selectedTeam", teamId);
      } else {
        teamId = localStorage.getItem("selectedTeam");
      }
      result = await getPermissonsUser(teamId, config);
    } else {
      result = await getPermissonsUser(null, config);
    }
    store.dispatch("SET_VALUE", {
      key: "allScopes",
      value: result.allScopes,
    });
    // Смешиваем с человекочитаемым названием
    mergePermissionsMix(result.scopes, result.allScopes);
    if (pushAfterInit === '/login') {
      pushAfterInit = '/'
    }
  } catch (error) {
    console.info("fail update token", error);
    pushAfterInit = "/login";
  }
}

// Не смог добраться до миксина отсюда, надо подумать куда вынести
function mergePermissionsMix(obj1, obj2) {
  // Объединяем массивы billing
  obj1.billing.forEach((item1) => {
    const billing = obj2.billing.find((item2) => item2.code === item1.code);

    if (billing) {
      Object.assign(item1, billing);
    }
  });
  // Объединяем массивы team
  obj1.team.forEach((item1) => {
    const team = obj2.team.find((item2) => item2.code === item1.code);

    if (team) {
      Object.assign(item1, team);
    }
  });
  obj1.config = obj2.config;
  store.dispatch("SET_VALUE", {
    key: "scopesAndPlans",
    value: obj1,
  });

  return obj1;
}

async function getUserInfo(config, userId) {
  const url = config.BASE_URL + "/api/v1/auth/users/" + userId + "/";

  await axios.get(url).then((resp) => {
    store.dispatch("SET_VALUE", {
      key: "user",
      value: { ...resp.data, user_id: userId },
    });
  });
}

async function addScript(config) {
  return new Promise((res) => {
    var script = document.createElement("script");
    script.src = config.ZARBO_VIEWER_URL;
    script.onload = function () {
      res();
    };
    document.head.appendChild(script);
  });
}

function setAxiosSettings(config) {
  axiosInstance.defaults.baseURL = config.BASE_URL;
  Vue.prototype.$axios = axiosInstance;
}

Vue.config.productionTip = false;

export const eventBus = new Vue();

async function initApp() {
  const config = await getConfig();
  const tips = await getTips();
  setAxiosSettings(config);
  await getTeams(config);
  await checkAuth(config);
  await addScript(config);
  Vue.prototype.$config = config;
  Vue.prototype.$tips = tips;

  await new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
  if (!pushAfterInit.includes("/confirm")) {
    // если pushAfterInit - основная страница, то идет ошибка NavigationDuplicated: Avoided redundant navigation to current location: "/".
    router.push(pushAfterInit).catch((error) => {
      console.log(error);
    }); // роутер работает только после инициализции приложения, потому сначала запоминаем маршрут и лишь после идём по нему
  }

  if (
    (initRoute !== pushAfterInit) &
    (initRoute !== "/reset") &
    (initRoute !== "/confirm") &
    (initRoute !== "/confirm-email") &
    (initRoute !== "/password_reset")
  ) {
    store.dispatch("SET_VALUE", { key: "initRoute", value: initRoute });
  }
}

initApp();
