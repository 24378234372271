<template>
  <v-card class="create-collection">
    <v-icon
      @click="closePopup"
      color="white"
      class="create-collection__close"
      size="30"
      >$vuetify.icons.closeRounded</v-icon
    >
    <v-card-title class="pa-0">
      Удалить коллекцию {{ collection.name }}?
    </v-card-title>
    <v-card-text class="pa-0 create-collection__text">
      <span>Коллекция {{ collection.name }} будет навсегда удалена!</span>
    </v-card-text>
    <v-card-actions class="pa-0 mt-5 create-collection__actions">
      <v-btn
        data-qa="btn-cancel-delete-collection"
        outlined
        color="primary"
        class="px-4"
        @click="closePopup"
        >Не удалять</v-btn
      >
      <v-btn
        data-qa="btn-delete-collection"
        depressed
        outlined
        class="px-7 btn__err btn__err-active"
        color="error"
        @click="deleteCollection(collection)"
        >Удалить</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["collection"],
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.appStore.user;
    },

    products() {
      return this.$store.state.appStore.products;
    },
  },
  methods: {
    closePopup() {
      this.$emit("close-popup");
    },
    async deleteCollection(collection) {
      const url =
        this.$config.BASE_URL + "/api/v1/collections/" + collection.id + "/";
      try {
        await this.$axios.delete(url);
        this.$emit("get-colllections");
        // Удаляем продукт и обновляем хранилище
        let newProducts = this.products.filter(
          (product) => product.collection_id !== collection.id
        );

        this.$store.dispatch("SET_VALUE", {
          key: "products",
          value: newProducts,
        });

        let lastCollections = localStorage.getItem("zarbo-selected-collection");
        lastCollections = JSON.parse(lastCollections);
        if (
          lastCollections[this.user.user_id] &&
          lastCollections[this.user.user_id].includes(collection.key)
        ) {
          lastCollections[this.user.user_id].splice(
            lastCollections[this.user.user_id].indexOf(collection.key),
            1
          );
          localStorage.setItem(
            "zarbo-selected-collection",
            JSON.stringify(lastCollections)
          );
        }
        this.$notify({
          group: "foo",
          title: "Удаление коллекции",
          text: "Коллекция <b>" + collection.name + "</b> успешно удалена",
          type: "success",
        });
        this.$emit("close-popup");
      } catch (error) {
        this.$notify({
          group: "foo",
          title: "Удаление коллекции",
          text: "Не удалось удалить коллекцию",
          type: "error",
        });
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.create-collection {
  padding: 24px 30px 30px 30px;
  position: relative;
  border-radius: 10px !important;
  .v-card__title {
    font-size: 20px !important;
    color: #212121;
    font-weight: 700 !important;
  }
  &__text {
    span {
      font-size: 15px;
      color: #666666;
    }
  }
  &__close {
    position: absolute !important;
    top: -40px;
    right: 0px;
  }
  &__actions {
    .v-btn:not(.v-btn--round).v-size--default {
      height: 44px;
    }
  }
}
</style>