<template>
  <div class="mt-14">
    <!-- ТЕГИ -->
    <div class="tags">
      <div
        class="tags__header"
        :style="{
          padding: !isSearchTag ? '0 20px 20px 20px' : '0 20px 12px 20px',
        }"
      >
        <!-- без строки поиска -->
        <div
          class="tags__header-icons d-flex justify-space-between"
          v-if="!isSearchTag"
        >
          <span class="tags__title">Теги</span>
          <div class="tags__controls d-flex align-center">
            <TipsVue :keyTips="'tag-search'">
              <v-icon
                color="primary"
                size="18"
                @click="isSearchTag = !isSearchTag"
                >$vuetify.icons.search</v-icon
              >
            </TipsVue>
            <PermissionWrapperVue
              :permissionCode="'create_tag'"
              :textStyles="{ paddingTop: '14px', paddingBottom: '14px' }"
              :iconStyles="{ right: '-18px', top: '-10px' }"
              :nudgeBottom="20"
            >
              <TipsVue :keyTips="'tag-add'">
                <v-icon
                  @click="opentCrateTag()"
                  color="primary"
                  size="18"
                  class="ml-3"
                  >$vuetify.icons.tinyPlus</v-icon
                >
              </TipsVue>
            </PermissionWrapperVue>
          </div>
        </div>
        <!-- с строкой поиска -->
        <div class="tags__header-search" v-else>
          <v-text-field
            class="tags__header-search-input rounded-zarbo"
            v-model="tagSearch"
            hide-details
            filled
            rounded
            autofocus
            solo
            flat
            color="primary"
            placeholder="Введите название тега"
          >
            <template #append>
              <v-icon
                @click="
                  isSearchTag = !isSearchTag;
                  tagSearch = '';
                "
                size="18"
                color="primary"
                >$vuetify.icons.plusRotate</v-icon
              >
            </template>
          </v-text-field>
        </div>
      </div>
      <div class="tags__body overflow-y-auto">
        <PermissionWrapperVue :permissionCode="'read_tag'" :nudgeBottom="20">
          <div
            v-if="filteredTags && filteredTags.length"
            class="tags__body-wrapper"
          >
            <TagVue
              v-for="tag in filteredTags"
              @click="addTag"
              @edit="editTag($event)"
              @delete="deleteTag($event)"
              class="tags__body-single-tag"
              editable
              :key="tag.id"
              :tag="tag"
            ></TagVue>
          </div>
          <div v-else>
            По запросу <b>{{ tagSearch }}</b> ничего не найдено
          </div>
        </PermissionWrapperVue>
      </div>
    </div>
    <!-- тег удаление, создание, редактирование -->
    <v-dialog
      width="520px"
      v-model="isTagPopup"
      transition="dialog-bottom-transition"
      @keydown.esc="isTagPopup = false"
    >
      <TagPopupCRUDVue
        :mode="tagPopupMode"
        :tag="tagToPopup"
        @close-popup="(isTagPopup = false), tagPopupReset++"
        :key="tagPopupReset"
        class="products-menu-wrapper__tag-create"
      />
    </v-dialog>
  </div>
</template>

<script>
import TagVue from "../Tags/TagVue.vue";
import TagPopupCRUDVue from "../Tags/TagPopupCRUDVue.vue";
import { eventBus } from "@/main.js";
import PermissionWrapperVue from "@/components/Utils/PermissionWrapperVue.vue";
import TipsVue from '@/components/Utils/TipsVue.vue'

export default {
  name: "menu-tags",
  components: {
    TagVue,
    TagPopupCRUDVue,
    PermissionWrapperVue,
    TipsVue
  },
  data: () => ({
    isSearchTag: false,
    tagSearch: "",
    tags: [],
    tagToPopup: null,
    tagPopupReset: 0,
    isTagPopup: false,
    tagPopupMode: "edit",
  }),

  computed: {
    filteredTags() {
      return this.tags
        .filter((tag) => tag.text.indexOf(this.tagSearch) !== -1)
        .sort((a, b) => b.count - a.count);
    },
    selectedCollection() {
      return this.$store.state.appStore.selectedCollection;
    },
    selectedTeam() {
      return this.$store.state.appStore.selectedTeam;
    },
    collections() {
      const collections = this.$store.state.appStore.collections;
      if (collections) {
        return collections;
      }
      return [];
    },
  },

  mounted() {
    this.getAllTags();
    eventBus.$on("update-all-tags", this.getAllTags);
  },

  beforeDestroy() {
    eventBus.$off("update-all-tags");
  },

  methods: {
    opentCrateTag() {
      this.tagPopupMode = "create";
      this.isTagPopup = true;
    },

    deleteTag(tag) {
      this.tagPopupMode = "delete";
      this.tagToPopup = tag;
      this.isTagPopup = true;
    },

    editTag(tag) {
      this.tagPopupMode = "edit";
      this.tagToPopup = tag;
      this.isTagPopup = true;
    },

    addTag(tag) {
      if (!this.selectedCollection.length) {
        return;
      }
      this.$store.dispatch("ADD_SEARCH_TAG", tag);
    },

    async getAllTags() {
      const team = localStorage.getItem("selectedTeam");
      const url =
        this.$config.BASE_URL +
        (team ? "/api/v1/tags/?team=" + team : "/api/v1/tags/");
      try {
        let res = await this.$axios.get(url);
        this.tags = res.data;
      } catch (error) {
        console.error("fail get all tags", error);
      }
    },
  },
};
</script>

<style lang="scss">
.tags {
  &__header {
    border-bottom: 1px solid #e0e0e0;
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    color: #212121;
  }

  &__header-search-input {
    max-height: 44px;

    .v-input__control .v-input__slot {
      background-color: #ffffff !important;
      padding: 0 16px;
    }

    .v-input__append-inner {
      margin-top: 13px;
    }

    .v-text-field.v-text-field--solo .v-input__control {
      min-height: 44px !important;
      max-height: 44px !important;
    }
  }

  &__body {
    height: calc(100vh - 68px);
    padding: 10px 20px;

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &-single-tag {
      margin-right: 10px;
      margin-top: 10px;
    }
  }
}
</style>
