import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/components/Product/ProductsVue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/Login/LoginVue"),
  },
  {
    path: "/tariff/:id",
    name: "tariff",
    component: () => import("@/components/Tariff/TariffVue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("@/components/User/UserVue"),
  },
  {
    path: "/design",
    name: "design",
    component: () => import("@/components/DesignSystem/DesignSystemVue"),
  },
  {
    path: "/reset",
    name: "reset",
    component: () => import("@/components/Login/LoginVue"),
  },
  {
    path: "/confirm/:confirm",
    name: "confirm",
    props: (route) => ({ confirm: route.params.confirm }),
    component: () => import("@/components/Login/LoginVue"),
  },
  {
    path: "/confirm-email",
    name: "confirm-email",
    component: () => import("@/components/Login/LoginVue"),
  },
  {
    path: "/password_reset/token/:token",
    name: "password_reset",
    props: (route) => ({ token: route.params.token }),
    component: () => import("@/components/Login/LoginVue"),
  },
  {
    path: "/teams",
    name: "teams",
    component: () => import("@/components/Teams/MyTeamsVue"),
  },
  {
    path: "/render",
    name: "render",
    component: () => import("@/components/Render/RenderPageVue"),
    children: [
      {
        path: "new",
        name: "create-render",
        component: () => import("@/components/Render/CRUD/CreateRenderVue.vue"),
      },
      {
        path: ":id",
        name: "create-render-edit",
        component: () => import("@/components/Render/CRUD/CreateRenderVue.vue"),
      },
    ],
  },
  {
    path: "/ai-photo",
    name: "ai-photo",
    component: () => import("@/components/AiPhoto/Page/AiPhotoPageVue.vue"),
    children: [
      {
        path: "new",
        name: "ai-photo-new",
        component: () => import("@/components/AiPhoto/Stepper/StepperVue.vue"),
      },
      {
        path: ":id",
        name: "ai-photo-edit",
        component: () => import("@/components/AiPhoto/Stepper/StepperVue.vue"),
      },
    ],
  },
  {
    path: "/social/complete",
    name: "social",
    props: (route) => ({ token: route.query.refresh }),
    component: () => import("@/components/Login/LoginVue"),
  },
  {
    path: "/oauth/:provider",
    name: "oauth",
    props: (route) => ({ params: route.query }),
    component: () => import("@/components/Utils/OauthProviderVue.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.path === "/render/new" ||
    to.path === "/render" ||
    to.path.startsWith("/render/")
  ) {
    store.dispatch("SET_VALUE", { key: "mode", value: "render" });
  } else if (
    to.path === "/ai-photo/new" ||
    to.path === "/ai-photo" ||
    to.path.startsWith("/ai-photo/")
  ) {
    store.dispatch("SET_VALUE", { key: "mode", value: "ai-photo" });
  } else {
    store.dispatch("SET_VALUE", { key: "mode", value: null });
  }

  if (from.path === "/render/new" || from.path.startsWith("/render/")) {
    store.dispatch("SET_VALUE", { key: "product", value: null });
    store.dispatch("SET_VALUE", { key: "productId", value: null });
    store.dispatch("SET_VALUE", { key: "renderStep", value: 1 });
    store.dispatch("SET_VALUE", { key: "selectedModelForRender", value: null });
    store.dispatch("SET_VALUE", { key: "render", value: null });
    store.dispatch("SET_VALUE", { key: "renderName", value: null });
  }

  if (from.path === "/ai-photo/new" || from.path.startsWith("/ai-photo/")) {
    store.dispatch("SET_VALUE", { key: "product", value: null });
    store.dispatch("SET_VALUE", { key: "productId", value: null });
    store.dispatch("SET_VALUE", { key: "selectedPhoto", value: null });
    store.dispatch("SET_VALUE", { key: "AiPhotoStep", value: 0 });
  }

  const patternRender = /^\/render(\/|$)/;

  if (
    patternRender.test(to.path) &&
    store.state.permissions.scopesAndPlans.length !== 0
  ) {
    let render = store.state.permissions.scopesAndPlans.billing.find(
      (scope) => scope.code === "render360"
    );

    if (render) {
      store.dispatch("SET_VALUE", { key: "lastLocation", value: from.path });
      next();
    } else next(false);
  } else {
    store.dispatch("SET_VALUE", { key: "lastLocation", value: from.path });
    next();
  }
});

export default router;
