<template>
  <div class="teams">
    <div v-if="loadingTeams" class="d-flex justify-center align-center pt-16">
      <v-progress-circular indeterminate color="primary"> </v-progress-circular>
    </div>
    <div
      v-if="isDataReady"
      class="d-flex flex-column justify-space-between mt-14"
      style="height: 94vh"
    >
      <div style="overflow: scroll;">
        <h5
          class="ml-5 mb-6"
          style="font-weight: 600; font-size: 20px; color: #212121"
        >
          Команды
        </h5>
        <v-card
          v-for="team in myTeams"
          :key="team.team.id"
          @click="changeTeam(team.team.slug)"
          class="d-flex teams__card align-center pt-6 pb-6 pl-6 ml-1 mb-1 mr-1"
          :style="
            activeTeam.team.id === team.team.id
              ? 'background-color: #EFEFFF'
              : ''
          "
          flat
          style="cursor: pointer"
        >
          <v-icon v-if="!team.team.owner.avatar" size="38"
            >$vietify.icons.userProfile</v-icon
          >
          <!-- Если аватарка отдается 404 - надо подумать как подставлять свою картинку, возможно запрашивать картинку и передвать в src байты -->
          <div
            v-else
            :style="{
              'background-image': 'url(' + team.team.owner.avatar + ')',
            }"
            class="teams__card-avatar"
          ></div>
          <div class="ml-3">
            <v-card-title
              class="pa-0"
              style="font-size: 16px; color: #4e4087"
              >{{ team.team.name }}</v-card-title
            >
            <v-card-text class="pa-0">{{ team.role.name }}</v-card-text>
          </div>
        </v-card>
      </div>
      <v-btn
        @click="openTeams()"
        depressed
        color="secondary"
        class="elevation-0 teams__btn mb-6 ml-6"
        >Мои команды<v-icon size="18" color="#4E4087" class="ml-4">
          $vuetify.icons.cog
        </v-icon></v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "@/main";
import { getPermissonsUser } from "../service/api";

export default {
  name: "menu-teams",
  components: {},
  data: () => ({
    myTeam: {},
    myTeams: [],
    activeTeam: {},
    isDataReady: false,
    loadingTeams: false,
  }),

  computed: {
    ...mapState({
      user: (state) => state.appStore.user,
      selectedCollection: (state) => state.appStore.selectedCollection,
    }),
  },

  async mounted() {
    await this.getMyTeam();
    this.isDataReady = true;

    eventBus.$on("changeTeam", (data) => {
      // console.log(data);
      const team = {
        role: data.role,
        team: data.team,
      };

      this.$store.dispatch("SET_VALUE", {
        key: "selectedTeam",
        value: team,
      });

      this.changeTeam(data.team.slug);
    });

    this.myTeams.forEach((team) => {
      if (team.team.slug === localStorage.getItem("selectedTeam")) {
        this.activeTeam = team;
        this.$store.dispatch("SET_VALUE", {
          key: "selectedTeam",
          value: team,
        });
      }
    });
  },

  methods: {
    async changeTeam(id) {
      let url = this.$config.BASE_URL + "/api/v1/collections/?team=" + id;

      try {
        this.$axios.get(url).then((resp) => {
          if (resp.data) {
            this.$store.dispatch("SET_VALUE", {
              key: "collections",
              value: resp.data,
            });

            let lastCollectionKey =
              resp.data && resp.data[resp.data.length - 1]
                ? resp.data[resp.data.length - 1].key
                : null;

            let arr = [];

            arr.push(lastCollectionKey);

            this.$store.dispatch("SET_VALUE", {
              key: "selectedCollection",
              value: arr,
            });
          }
          // eventBus.$emit("get-collection-products", "newTeam"); // оврде и без этого работает
          eventBus.$emit("update-all-collections", true);
          eventBus.$emit("update-all-tags");
        });
        this.myTeams.forEach((team) => {
          if (team.team.slug === id) {
            this.activeTeam = team;
            this.$store.dispatch("SET_VALUE", {
              key: "selectedTeam",
              value: team,
            });

            localStorage.setItem("selectedTeam", team.team.slug);
          }
        });
        // Запрашиваем Разрешения
        let result = await getPermissonsUser(
          localStorage.getItem("selectedTeam"),
          this.$config
        );

        this.mergePermissionsMix(result.scopes, result.allScopes);
      } catch (error) {
        console.error(error);
      }
    },
    openTeams() {
      this.$router.push("/teams");
    },
    async getMyTeam() {
      this.loadingTeams = true;
      let url = `${this.$config.BASE_URL}/api/v1/teams/?limit=100`;
      this.myTeams = [];
      this.activeTeam = {};
      this.$store.dispatch("SET_VALUE", {
        key: "selectedTeam",
        value: {},
      });

      try {
        const resp = await this.$axios({ url, method: "GET" });

        if (resp.data.count === 1) {
          this.myTeam = resp.data.results[0];
          this.myTeams.push(resp.data.results[0]);
          this.activeTeam = resp.data.results[0];
          this.$store.dispatch("SET_VALUE", {
            key: "selectedTeam",
            value: resp.data.results[0],
          });
        } else {
          resp.data.results.forEach((team) => {
            if (team.role.codename === "owner") {
              this.myTeam = team;
              this.activeTeam = team;
              this.$store.dispatch("SET_VALUE", {
                key: "selectedTeam",
                value: team,
              });
              this.myTeams.push(team);
              this.$store.dispatch("SET_VALUE", {
                key: "myTeam",
                value: this.myTeam,
              });
            } else {
              this.myTeams.push(team);

              this.$store.dispatch("SET_VALUE", {
                key: "allTeams",
                value: this.myTeams,
              });
            }
          });
        }

        this.loadingTeams = false;
      } catch (error) {
        const err = this.handleErrorMix(error, false);

        this.$notify({
          group: "foo",
          title: "Не удалось загрузить список команд",
          text: err.detail,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.teams {
  &__owner {
    color: #4e4087;
  }
  &__owner-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    background: #efefff;
    height: 32px;
    width: 300px;
    .teams__owner {
      width: 220px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__owner-avatar {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
  &__card {
    height: 82px;
    border: 1px solid #e0e0e0 !important;
    border-radius: 10px !important;
    &-avatar {
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      border-radius: 50%;
      width: 38px;
      height: 38px;
    }
    .v-card__title {
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__btn {
    width: 251px !important;
    height: 40px !important;
  }
}
</style>
