export default {
  state: {
    searchTags: []
  },
  mutations: {
    setValueModule (state, keyValue) {
      // console.log('set value module render')
      if (keyValue.key in state) {
        state[keyValue.key] = keyValue.value
      }
    },
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    },
    addSearchTag (state, tagToAdd) {
      let indexDelete = state.searchTags.findIndex(tag => {
        return tag.id === tagToAdd.id
      })
      if (indexDelete === -1) {
        state.searchTags.push(tagToAdd)
      }
    },
    removeSearchTag (state, tagToDelete) {
      let indexDelete = state.searchTags.findIndex(tag => {
        return tag.id === tagToDelete.id
      })
      if (indexDelete !== -1) {
        state.searchTags.splice(indexDelete, 1)
      }
    },
    renameSearchTag (state, tagWithNewName) {
      let oldTagIndex = state.searchTags.findIndex(tag => {
        return tag.id === tagWithNewName.id
      })

      if (oldTagIndex !== -1) {
        state.searchTags[oldTagIndex].text = tagWithNewName.text
      }
    }
  },
  actions: {
    SET_VALUE_MODULE ({commit}, keyValue) {
      commit('setValueModule', keyValue)
    },
    ADD_SEARCH_TAG({commit}, tag) {
      commit('addSearchTag', tag)
    },
    REMOVE_SEARCH_TAG({commit}, tag) {
      commit('removeSearchTag', tag)
    },
    RENAME_SEARCH_TAG({commit}, newTag) { // id должен быть тот же, а имя новое
      commit('renameSearchTag', newTag)
    },
    SET_VALUE ({ commit }, keyValue) {
      commit('setValue', keyValue)
    }
  }
}
