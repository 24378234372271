<template>
  <v-card class="elevation-0 rounded-zarbo" width="520">
    <v-card-title class="pa-0 mb-2">{{ cardTitle }}</v-card-title>
    <v-card-text class="pa-0">
      <v-form v-model="validTagName" onSubmit="return false;">
        <div v-if="mode === 'create'">
          <div class="d-flex justify-start">
            <v-text-field
              @keydown.space.prevent
              @input="createError = ''"
              v-model="newTagName"
              height="44"
              rounded
              dense
              background-color="secondary"
              color="#8786A8"
              class="rounded-zarbo zarbo-text-field"
              flat
              label="Название тега"
              ref="input-create-tag"
              :rules="tagNameRules"
              :loading="isLoading"
              :error-messages="createError"
            />
            <!-- Вывод совпадающих тегов -->
            <div
              v-if="createError"
              class="d-flex align-self-center"
              style="
                min-height: 32px;
                padding: 7px 15px;
                background: #e6e6fc;
                border-radius: 5px;
                margin-bottom: 4px;
              "
            >
              <p class="align-self-center" style="color: #232451">
                {{ tagInErr }}
              </p>
            </div>
          </div>

          <div :disabled="!validTagName" class="block-btn ml-2 mt-2">
            <button :disabled="!validTagName" @click="createTag" class="mybtn">
              Создать тег
            </button>
          </div>
          <!-- createError -->
          <!-- <v-alert color="error" v-show="createError" text class="mt-5 login__err" icon="mdi-information">
              Не удалось создать тег. {{ createError }}
            </v-alert> -->
        </div>
        <div v-else-if="mode === 'edit'">
          <div class="d-flex justify-start">
            <v-text-field
              @keydown.space.prevent
              @input="editError = ''"
              v-model="newTagName"
              rounded
              height="44"
              dense
              background-color="secondary"
              color="#8786A8"
              flat
              class="rounded-zarbo zarbo-text-field mt-0"
              label="Новое название тега"
              ref="input-edit-tag"
              :rules="tagNameRules"
              :loading="isLoading"
              :error-messages="editError"
            />
            <!-- Вывод совпадающих тегов -->
            <div
              v-if="editError"
              class="d-flex align-self-center"
              style="
                min-height: 32px;
                padding: 7px 15px;
                background: #e6e6fc;
                border-radius: 5px;
                margin-bottom: 4px;
              "
            >
              <p class="align-self-center" style="color: #232451">
                {{ tagInErr }}
              </p>
            </div>
          </div>

          <div :disabled="!validTagName" class="block-btn ml-2 mt-0">
            <button @click="editTag" :disabled="!validTagName" class="mybtn">
              Переименовать
            </button>
          </div>
        </div>
        <div v-else>
          <span>Тег будет удален из всех продуктов и из панели тегов.</span>
          <div>
            <v-btn
              outlined
              color="primary"
              class="mt-4"
              @click="$emit('close-popup')"
              >Не удалять</v-btn
            >
            <v-btn
              @click="deleteTag"
              color="error"
              class="ml-3 mt-4 btn__err btn__err-active"
              depressed
              outlined
              >Удалить</v-btn
            >
          </div>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { eventBus } from "@/main.js";

export default {
  name: "tag-popup",
  props: {
    mode: {
      type: String,
      required: true,
      validator: function (value) {
        // Значение должно соответствовать одной из этих строк
        return ["create", "delete", "edit"].includes(value);
      },
    },
    tag: {
      type: Object,
    },
  },

  data: () => ({
    newTagName: "",
    isLoading: false,
    validTagName: null,
    tagNameRules: [
      (e) => (e ? true : "Поле не должно быть пустым"),
      (e) => (e.length > 3 ? true : "Длина должна быть больше 3 символов"),
      (e) => (e.length < 15 ? true : "Длина должна быть меньше 15 символов"),
      (e) =>
        /[^a-zA-Zа-яА-Я0-9_\-+ ]/g.test(e)
          ? 'Некорректное имя тега (разрешены символы: а-я, А-Я, a-z, A-Z, 0-9, "-", "+", "_")'
          : true,
    ],
    createError: "",
    editError: "",
    tagInErr: "",
  }),

  computed: {
    cardTitle() {
      switch (this.mode) {
        case "create":
          return "Создать тег";
        case "delete":
          return "Удалить тег?";
        case "edit":
          return `Переименовать тег «${this.tag.text}»`;

        default:
          return "";
      }
    },
    selectedTeam() {
      return this.$store.state.appStore.selectedTeam;
    },
  },

  methods: {
    async editTag() {
      if (this.$refs["input-edit-tag"].hasError) {
        return;
      }
      this.editError = "";
      this.tagInErr = "";
      let url = this.$config.BASE_URL + "/api/v1/tags/" + this.tag.id + "/";
      let data = new FormData();
      data.append("text", this.newTagName);
      this.isLoading = true;
      try {
        let resp = await this.$axios.patch(url, data);
        this.isLoading = false;
        // console.log('resp.data')
        this.$store.dispatch("RENAME_SEARCH_TAG", resp.data);
        this.$emit("close-popup");
        eventBus.$emit("update-all-tags");
        eventBus.$emit("edit-tag", {
          id: this.tag.id,
          text: this.newTagName,
        });
      } catch (error) {
        this.editError = error.response.data.detail;
        this.tagInErr = error.response.data.tag.text;
        this.isLoading = false;
      }
    },

    async createTag() {
      if (this.$refs["input-create-tag"].hasError) {
        return;
      }
      this.createError = "";
      this.tagInErr = "";
      let url = this.$config.BASE_URL + "/api/v1/tags/";
      let data = new FormData();
      data.append("text", this.newTagName);
      if (this.selectedTeam?.team?.slug) {
        data.append("team", this.selectedTeam.team.slug);
      }
      this.isLoading = true;
      try {
        await this.$axios.post(url, data);
        this.isLoading = false;
        this.$emit("close-popup");
        eventBus.$emit("update-all-tags");
      } catch (error) {
        console.error("fail create tag", error);

        this.isLoading = false;
        this.createError = error.response.data[0].detail;
        this.tagInErr = error.response.data[0].extra_data.tag.text;
      }
    },

    async deleteTag() {
      let url = this.$config.BASE_URL + "/api/v1/tags/" + this.tag.id + "/";
      this.isLoading = true;
      try {
        await this.$axios.delete(url);
        this.isLoading = false;
        this.$store.dispatch("REMOVE_SEARCH_TAG", this.tag);
        this.$emit("close-popup");
        eventBus.$emit("update-all-tags");
        eventBus.$emit("delete-tag", this.tag);
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          group: "foo",
          title: "Тег не создан",
          text: "Что-то пошло не так",
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.tag-create {
  width: 300px !important;
}
</style>