import { render, staticRenderFns } from "./CubeIconVue.vue?vue&type=template&id=0fc1d92c"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports