<template>
  <v-card class="create-collection">
    <v-icon
      @click="closePopup"
      color="white"
      class="create-collection__close"
      size="30"
      >$vuetify.icons.closeRounded</v-icon
    >
    <v-card-title class="pa-0 mb-2">
      Переименовать коллекцию {{ collection.name }}
    </v-card-title>
    <v-card-text class="pa-0 create-collection__text">
      <span>Введите название коллекции</span>
      <v-text-field
        data-qa="edit-name-collection"
        @keydown.enter="editCollection(collectionForm)"
        v-model="collectionName"
        ref="renameCollection"
        placeholder="Имя коллекции"
        class="create-collection__input"
        solo
        flat
        hide-details
        background-color="secondary"
        dense
        :rules="[(v) => !!v || 'Имя обязательное поле']"
      >
      </v-text-field>
    </v-card-text>
    <v-card-actions class="pa-0 pl-2">
      <div class="block-btn">
        <button
          data-qa="btn-rename-collection"
          @click="editCollection(collectionForm)"
          :disabled="collectionName.trim() === ''"
          class="mybtn create-collection__btn"
        >
          Переименовать
        </button>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["collection"],
  data() {
    return {
      collectionForm: {},
      collectionName: "",
    };
  },
  watch: {
    collection(newV) {
      this.collectionForm = newV;
      this.collectionName = newV.name;
    },
  },
  mounted() {
    this.collectionForm = this.collection;
    this.collectionName = this.collection.name;
  },
  methods: {
    closePopup() {
      this.$emit("close-popup");
      this.collectionName = this.collection.name;
    },
    async editCollection(collection) {
      const url =
        this.$config.BASE_URL + "/api/v1/collections/" + collection.id + "/";
      collection.name = this.collectionName;
      try {
        await this.$axios.patch(url, collection);
        this.isConfirmEditCollection = false;
        this.$notify({
          group: "foo",
          title: "Редактирование коллекции",
          text: "Коллекция <b>" + collection.name + "</b> успешно изменена",
          type: "success",
        });
        this.$emit("close-popup");
        this.$refs.renameCollection.resetValidation();
        this.collectionName = this.collection.name;
      } catch (error) {
        this.isConfirmEditCollection = false;
        this.$notify({
          group: "foo",
          title: "Удаление коллекции",
          text: "Не удалось изменить коллекцию",
          type: "error",
        });

        this.$refs.renameCollection.resetValidation();
      }
    },
  },
};
</script>

<style lang="scss">
.create-collection {
  padding: 24px 30px 30px 30px;
  position: relative;
  border-radius: 10px !important;
  .v-card__title {
    font-size: 20px !important;
    color: #212121;
    font-weight: 700 !important;
  }
  &__text {
    span {
      font-size: 15px;
      color: #666666;
    }
  }
  &__close {
    position: absolute !important;
    top: -40px;
    right: -7px;
  }
  &__input {
    .v-input__control {
      border-radius: 10px;
      max-width: 300px;
      margin-bottom: 15px;
      .v-input__slot {
        height: 44px;
      }
    }
  }
  &__btn {
    padding: 12px 10px;
  }
}
</style>