import axios from "axios";

export async function getPermissonsUser(teamSlag, config) {
  // запрашивает скопы пользователя и человекочитаемые названия
  // Вынести и запрашивать один раз
  const urlAllPermissions = config.BASE_URL + "/api/v1/scopes/all";
  let url;

  if (teamSlag !== null) {
    url = config.BASE_URL + `/api/v1/scopes/?team=${teamSlag}`;
  } else {
    url = config.BASE_URL + "/api/v1/scopes/";
  }

  let scopes;
  let allScopes;

  try {
    // Запрашиваем скопы пользователя
    await axios.get(url).then((res) => {
      scopes = res.data;
    });
    // Запрашиваем все скопы
    await axios.get(urlAllPermissions).then((res) => {
      allScopes = res.data;
    });

    return { scopes, allScopes };
  } catch (error) {
    console.error(error);
  }
}

export async function getYoutubePlaylist(config) {
  const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${config.YOUTUBE_PLAYLIST_ID}&key=${config.API_KEY_YOUTUBE}`;

  try {
    const resp = await axios.get(url);

    return resp.data;
  } catch (error) {
    console.error(error);

    return [];
  }
}

export async function getScopes(teamSlag, config) {
  // запрашивает скопы пользователя
  let url;

  if (teamSlag !== null) {
    url = config.BASE_URL + `/api/v1/scopes/?team=${teamSlag}`;
  } else {
    url = config.BASE_URL + "/api/v1/scopes/";
  }

  const res = await axios.get(url);
  return res.data;
}

export async function changeWidgetParams(param, config, id, productId) {
  let url

  if (productId && Object.keys(param).includes('ar_placement')) {
    url = config.BASE_URL + "/api/v1/products/" + productId + "/"
  } else {
    url = config.BASE_URL + "/api/v1/widgets/" + id + "/"
  }

  try {
    return await axios.patch(url, param);
  } catch (error) {
    console.error(error);

    return {};
  }
}
