export default {
  state: {
    AiPhotoStep: 0,
    selectedPhoto: null,
    isCreatedAiPhoto: false,
    aiPhotoSettingsReGenerate: null,
  },
  mutations: {
    setValue(state, keyValue) {
      state[keyValue.key] = keyValue.value;
    },
    setValueModule (state, keyValue) {
      // console.log('set value module render')
      if (keyValue.key in state) {
        state[keyValue.key] = keyValue.value
      }
    },
  },
  actions: {
    SET_VALUE({ commit }, keyValue) {
      commit("setValue", keyValue);
    },
    SET_VALUE_MODULE ({commit}, keyValue) {
      commit('setValueModule', keyValue)
    },
  },
};
