import { getScopes } from "@/components/service/api";
import axios from "axios";
import jwt_decode from "jwt-decode";

export default {
  data: () => ({
    serverErrorMix: "",
    myMixVar: {
      // test: "test",
    },
  }),
  mounted() {
    for (const key in this.$data) {
      if (key.toLowerCase().includes("rule")) {
        if (Array.isArray(this.$data[key])) {
          this.$data[key].push(
            () =>
              this.myMixVar.passwordRepeat === undefined ||
              this.myMixVar.passwordRepeat
          );
        }
      }
    }
  },
  methods: {
    setdataMix() {
      this.myMixVar.passwordRepeat = "test2";
      this.myMixVar.test2 = "test2";
      setTimeout(() => {
        this.$refs.myFormZVO.validate();
      }, 3000);
    },
    // Обработка ошибок при запросах
    handleErrorMix(error, isNotify) {
      let responseData = error.response.data;

      if (!responseData[0].extra_data) {
        if (!isNotify) {
          return responseData[0];
        }

        this.$notify({
          group: "foo",
          text: responseData[0].detail,
          type: "error",
        });
      } else {
        this.serverErrorMix = {};

        let extraData = responseData[0].extra_data;

        for (let key in extraData) {
          if (Object.prototype.hasOwnProperty.call(extraData, key)) {
            this.serverErrorMix[key] = extraData[key];
          }
        }
      }
    },

    async updateScopesMix() {
      // перезапрпашивает скопы пользователя(или пользователя в команде) и обновляет их в хранилище
      // Запрашиваем Разрешения
      const team = localStorage.getItem("selectedTeam");
      const scopes = await getScopes(team !== "" ? team : null, this.$config);
      const allScopes = this.$store.state.permissions.allScopes;
      // Смешиваем с человекочитаемым названием
      this.mergePermissionsMix(scopes, allScopes);
    },

    mergePermissionsMix(obj1, obj2) {
      // Объединяем массивы billing
      obj1.billing.forEach((item1) => {
        const billing = obj2.billing.find((item2) => item2.code === item1.code);

        if (billing) {
          Object.assign(item1, billing);
        }
      });
      // Объединяем массивы team
      obj1.team.forEach((item1) => {
        const team = obj2.team.find((item2) => item2.code === item1.code);

        if (team) {
          Object.assign(item1, team);
        }
      });
      obj1.config = obj2.config;

      this.$store.dispatch("SET_VALUE", {
        key: "scopesAndPlans",
        value: obj1,
      });
      return obj1;
    },
    // Выбор моделей для 2 шага степпера (Используемые модели)
    getModelByTargetMix(product, type) {
      let modelTo = null;

      product.models.forEach((model) => {
        let arr = model.additional_data.split(" ");

        arr.forEach((item) => {
          if (type === "all") {
            const stringsToCheck = ["3d", "ar_android", "ar_ios"];
            const isAllStringsPresent = stringsToCheck.every((str) =>
              arr.includes(str)
            );
            if (isAllStringsPresent) {
              modelTo = model;
            }
          }
          if (item === type) {
            modelTo = model;
          }
        });
      });
      return modelTo;
    },

    async downloadZipMix(itemsToDownload, archiveName) {
      var JSZip = require("jszip");
      var JSZipUtils = require("jszip-utils");
      var FileSaver = require("file-saver");
      var zip = new JSZip();
      let errors = [];
      let counter = 0;

      // составление имени файла
      itemsToDownload.forEach(async function (item) {
        await JSZipUtils.getBinaryContent(item.url, async function (err, data) {
          if (err) {
            errors.push(err); // or handle the error
          }
          await zip.file(item.name, data, { binary: true });
          counter++;
          if (counter === itemsToDownload.length) {
            let content = await zip.generateAsync({ type: "blob" });
            FileSaver.saveAs(content, archiveName || "Zarbo-models");
          }
        });
      });
    },

    saveFileMix(file, name) {
      var FileSaver = require("file-saver");
      FileSaver.saveAs(file, name);
    },

    toCamelMix(s) {
      return s.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace("-", "").replace("_", "");
      });
    },

    async getWidgetsByProductIdMix(prodId) {
      if (!prodId) {
        console.warn("prodId is " + prodId);
        return null;
      }
      const url = this.$config.BASE_URL + "/api/v1/widgets/?product=" + prodId;
      try {
        let resp = await this.$axios.get(url);
        return resp.data;
      } catch (error) {
        console.error("fail get widgets", error);
        return null;
      }
    },

    getFileExtentionMix(file) {
      return file.name.split(".").splice(-1, 1)[0];
    },

    async getProductByIdMix(productId) {
      if (!productId) {
        return null;
      }
      const url = this.$config.BASE_URL + "/api/v1/products/" + productId + "/";

      try {
        const resp = await this.$axios.get(url);
        this.$store.dispatch("SET_VALUE", { key: "product", value: resp.data });
        return resp.data;
      } catch (error) {
        throw new Error("fail get product");
      }
    },

    getModelFromProductMix(product, format) {
      if (!Array.isArray(format)) {
        format = [format];
      }
      if (!product || product.models.length === 0 || !format) {
        return null;
      }
      const model = product.models.find((_model) => {
        return format.includes(_model.format); // совпадает формат и качество
      });
      return model;
    },

    getAndroidTooltipMix(product) {
      const color = this.getAndroidColorMix(product);
      return color === "green"
        ? "Установлена модель для просмотра на устройствах Android"
        : "Не установлена модель для просмотра на устройствах Android";
    },

    getIosTooltipMix(product) {
      const color = this.getIosColorMix(product);
      switch (color) {
        case "green":
          return "Установлена модель для просмотра на устройствах Apple";

        case "yellow":
          return 'Не установлена модель для просмотра на устройствах Apple. Модель конвертируется из модели для Android "на лету", однако, это может занять немного больше времени';

        case "red":
          return "Не установлена модель для просмотра на устройствах Apple";

        default:
          break;
      }
    },

    getWrbTooltipMix(product) {
      const color = this.getAndroidColorMix(product);
      return color === "green"
        ? "Установлена модель для просмотра в браузере"
        : "Не установлена модель для просмотра в браузере";
    },

    getAndroidColorMix(product) {
      const haveAndroid = product.models.find((item) => {
        return item.format === "glb" || item.format === "gltf";
      });
      return haveAndroid !== undefined ? "green" : "red";
    },

    getIosColorMix(product) {
      const haveIos = product.models.find((item) => {
        return item.format === "usdz";
      });
      if (haveIos === undefined) {
        const andr = this.getAndroidColorMix(product);
        return andr === "green" ? "yellow" : "red";
      } else {
        return "green";
      }
    },

    async refreshTokenMix() {
      const refreshToken = localStorage.getItem("zarboRefreshToken");
      const url = this.$config.BASE_URL + "/api/token/refresh/";

      if (!refreshToken) {
        await this.$router.push("/login");
        return;
      }
      try {
        const resp = await axios.post(url, { refresh: refreshToken });
        localStorage.setItem("zarboAccessToken", resp.data.access);
        let decoded = jwt_decode(resp.data.access);
        this.$store.dispatch("SET_VALUE", { key: "isAuthorized", value: true });
        this.updateScopesMix();
        return this.getUserInfoMix(decoded.user_id);
      } catch (error) {
        this.$router.push("/login");
        this.$store.dispatch("SET_VALUE", {
          key: "isAuthorized",
          value: false,
        });
        console.info("fail update token", error);
        return null;
      }
    },

    async getUserInfoMix(userId) {
      const url = this.$config.BASE_URL + "/api/v1/auth/users/" + userId + "/";
      await axios.get(url).then((resp) => {
        this.$store.dispatch("SET_VALUE", {
          key: "user",
          value: { ...resp.data, user_id: userId },
        });
        try {
          window.ym(92478487, "userParams", { UserID: userId });
        } catch (error) {
          console.info("metric disabled", error);
        }
      });
    },

    getQualitiesMix() {
      const url = this.$config.BASE_URL + "/api/v1/quality/";
      return this.$axios.get(url).then((resp) => {
        this.$store.dispatch("SET_VALUE", {
          key: "qualities",
          value: resp?.data?.results || [],
        });
      });
    },
  },
};
