export default {
  state: {
    productId: null,
    product: null,
    collectionToCreate: null
  },
  mutations: {
    setValueModule (state, keyValue) {
      // console.log('set value module render')
      if (keyValue.key in state) {
        state[keyValue.key] = keyValue.value
      }
    },
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    }
  },
  actions: {
    SET_VALUE_MODULE ({commit}, keyValue) {
      commit('setValueModule', keyValue)
    },
    SET_VALUE ({ commit }, keyValue) {
      commit('setValue', keyValue)
    }
  }
}
