import appStore from './appStore'
import masterCreateStore from './masterCreateStore'
import tagStore from './tagStore'
import renderStore from './renderStore'
import permissions from './permissions'
import productsStore from './productsStore'
import widgetStore from './widgetStore'
import aiPhotoStore from './aiPhotoStore'

export default {
  appStore,
  masterCreateStore,
  tagStore,
  renderStore,
  permissions,
  productsStore,
  widgetStore,
  aiPhotoStore
}
