export default {
  state: {
    scopesAndPlans: [],
    allPermissions: [],
    allScopes: []
  },
  mutations: {
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    },
    setValueModule (state, keyValue) {
      if (keyValue.key in state) {
        state[keyValue.key] = keyValue.value
      }
    },
  },
  actions: {
    SET_VALUE ({ commit }, keyValue) {
      commit('setValue', keyValue)
    },
    // тот же SET_VALUE, только пишет лишь в свой модуль хранилища, а не во все. Хочу его использовать как SET_VALUE, но страшно сразу менять во всем проекте 
    SET_VALUE_MODULE ({commit}, keyValue) {
      commit('setValueModule', keyValue)
    },
  }
}