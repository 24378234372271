<template>
  <v-card
    :loading="loadingCreateCollection"
    class="create-collection"
    data-qa="collection-create-form"
  >
    <v-icon
      @click="closePopup"
      color="white"
      class="create-collection__close"
      size="30"
      >$vuetify.icons.closeRounded</v-icon
    >
    <v-card-title class="pa-0 mb-2"> Создать коллекции </v-card-title>

    <v-card-text class="pa-0 create-collection__text">
      <v-form
        ref="formCreateCollection"
        @submit.prevent="createCollection"
        v-model="valid"
      >
        <span>Введите название коллекции</span>
        <v-text-field
          data-qa="create-name-collection"
          v-model="collectionName"
          ref="newCollectionRef"
          placeholder="Имя коллекции"
          class="create-collection__input"
          solo
          flat
          hide-details
          background-color="secondary"
          dense
          :rules="[(v) => !!v || 'Имя обязательное поле']"
        >
        </v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions class="pa-0 pl-2">
      <div class="block-btn" @click="createCollection">
        <button
          class="mybtn create-collection__btn"
          :disabled="!valid"
          data-qa="popup-create-collection"
        >
          Создать коллекцию
        </button>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "create-collection",
  data: () => ({
    valid: false,
    collectionName: "",
    loadingCreateCollection: false,
    error: "",
  }),

  computed: {
    selectedTeam() {
      return this.$store.state.appStore.selectedTeam;
    },
  },
  methods: {
    closePopup() {
      this.$emit("close-popup");
      this.collectionName = "";
      this.$refs.newCollectionRef.resetValidation();
    },

    async createCollection() {
      this.$refs.formCreateCollection.validate();
      if (this.valid === false) {
        return;
      }
      this.loadingCreateCollection = true;
      this.error = "";
      let data = new FormData();
      data.append("name", this.collectionName);

      if (this.selectedTeam?.team?.slug) {
        data.append("team", this.selectedTeam.team.slug);
      }

      const url = this.$config.BASE_URL + "/api/v1/collections/";
      try {
        const resp = await this.$axios.post(url, data);
        this.loadingCreateCollection = false;
        this.$store.dispatch("SET_VALUE", {
          key: "selectedCollection",
          value: resp.data,
        });
        this.$emit("update-collection");
        this.closePopup();
        this.collectionName = "";
        this.$refs.formCreateCollection.resetValidation();
      } catch (error) {
        console.error("fail create collection", error);
        this.loadingCreateCollection = false;
        this.error = this.handleErrorMix(error, false).detail;

        this.$refs.formCreateCollection.resetValidation();
      }
    },
  },
};
</script>

<style lang="scss">
.create-collection {
  padding: 24px 30px 30px 30px;
  position: relative;
  border-radius: 10px !important;
  .v-card__title {
    font-size: 20px !important;
    color: #212121;
    font-weight: 700 !important;
  }
  &__text {
    span {
      font-size: 15px;
      color: #666666;
    }
  }
  &__close {
    position: absolute !important;
    top: -40px;
    right: 0px;
  }
  &__input {
    .v-input__control {
      border-radius: 10px;
      max-width: 300px;
      margin-bottom: 15px;
      .v-input__slot {
        height: 44px;
      }
    }
  }
  &__btn {
    padding: 12px 10px;
  }
}
</style>