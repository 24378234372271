<template>
  <div
    class="tag"
    :style="{ ...tagStyle, ...tagBackground(tag.count) }"
    @click.stop="$emit('click', tag)"
  >
    <span class="tag__text" :style="tagTextStyle">
      {{ tag.text }}
    </span>
    <div class="tag__counter" v-if="editable">
      {{ tag.count ? tag.count : 0 }}
    </div>
    <div class="tag__editable" v-if="editable">
      <v-menu
        rounded="zarbo"
        left
        content-class="rounded-lg elevation-0"
        nudge-right="70px"
        nudge-bottom="25px"
      >
        <template v-slot:activator="{ on, attrs }">
          <div>
            <v-icon v-bind="attrs" v-on="on" size="19" color="primary"
              >mdi-menu</v-icon
            >
          </div>
        </template>
        <v-list dense style="border: 1px solid #cfcdf6; border-radius: 10px">
          <v-list-item-group>
            <PermissionWrapperVue
              :permissionCode="'update_tag'"
              :nudgeBottom="20"
            >
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-list-item @click.stop="$emit('edit', tag)">
                  <v-list-item-icon class="mr-1 my-auto">
                    <v-icon :color="hover ? 'primary' : ''" size="18"
                      >$vuetify.icons.edit</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title class="pl-1 tag__editable-title"
                    >Редактировать</v-list-item-title
                  >
                </v-list-item>
              </v-hover>
            </PermissionWrapperVue>

            <PermissionWrapperVue
              :permissionCode="'read_tag'"
              :nudgeBottom="20"
            >
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-list-item @click="$emit('click', tag)">
                  <v-list-item-icon class="mr-1 my-auto">
                    <v-icon :color="hover ? 'primary' : ''" size="18"
                      >$vuetify.icons.search</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title class="pl-1 tag__editable-title"
                    >Поиск по тегу</v-list-item-title
                  >
                </v-list-item>
              </v-hover>
            </PermissionWrapperVue>
            <PermissionWrapperVue
              :permissionCode="'delete_tag'"
              :nudgeBottom="20"
            >
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-list-item @click.stop="$emit('delete', tag)">
                  <v-list-item-icon class="mr-1 my-auto">
                    <v-icon
                      style="margin-left: -4px"
                      :color="hover ? 'red' : ''"
                      size="24"
                      >mdi-trash-can</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title class="pl-1 tag__editable-title"
                    >Удалить</v-list-item-title
                  >
                </v-list-item>
              </v-hover>
            </PermissionWrapperVue>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>
    <div class="tag__closable" v-if="closable">
      <v-icon :size="14" color="primary" @click.stop="$emit('close', tag)"
        >$vuetify.icons.closeRounded</v-icon
      >
    </div>
    <div class="tag__closable-every-time" v-if="closableEveryTime">
      <v-icon :size="10" color="primary" @click.stop="$emit('close', tag)"
        >$vuetify.icons.closeRounded</v-icon
      >
    </div>
  </div>
</template>

<script>
import PermissionWrapperVue from "@/components/Utils/PermissionWrapperVue.vue";

export default {
  components: {
    PermissionWrapperVue,
  },
  name: "tag-component",
  props: {
    tag: {
      required: true,
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    closable: {
      // Кнопка закрытия видна только при наведении
      type: Boolean,
      default: false,
    },
    closableEveryTime: {
      // кнопка закрытия видна всегда
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
      validator: function (value) {
        // Значение должно соответствовать одной из этих строк
        return ["big", "small"].includes(value);
      },
    },
  },
  data: () => ({}),
  computed: {
    tagStyle() {
      return {
        height: this.size === "small" ? "24px" : "32px",
        padding: this.size === "small" ? "3px 8px" : "7px 12px",
      };
    },

    tagTextStyle() {
      return {
        "font-size": this.size === "small" ? "14px" : "15px",
      };
    },
  },

  methods: {
    tagBackground(count) {
      if (count > 101) {
        return {
          "background-color": "#6A6DDF",
        };
      } else if (count > 81) {
        return {
          "background-color": "#C5C4F9",
        };
      } else if (count > 61) {
        return {
          "background-color": "#CECDFA",
        };
      } else if (count > 41) {
        return {
          "background-color": "#D6D5FB",
        };
      } else if (count > 21) {
        return {
          "background-color": "#DEDEFC",
        };
      }
      return {
        "background-color": "#E6E6FC",
      };
    },
  },
};
</script>

<style>
.tag {
  /* height: 24px;
  padding: 3px 8px; */
  border-radius: 5px;
  background-color: #e6e6fc;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  min-width: 43px;
}

.tag__text {
  color: #232451;
  width: 100%;
  padding-right: 5px;
}

.tag:hover .tag__editable {
  display: block;
}

.tag:hover .tag__counter {
  display: none;
}

.tag__editable {
  background-color: #e6e6fc;
  display: none;
  max-width: 20px;
  min-width: 20px;
}

.tag__counter {
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #6a6ddf;
  max-width: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag__edit-icon {
  margin-right: 5px;
}

.tag:hover .tag__closable {
  display: block;
}

.tag__closable {
  position: absolute;
  right: 8px;
  background-color: #e6e6fc;
  box-shadow: -6px 0px 8px 4px #e6e6fc;
  display: none;
}

.tag__closable button,
.tag__closable svg {
  width: 14px;
  height: 14px;
}

.tag__closable-every-time {
  right: 8px;
  padding-left: 5px;
  background-color: #e6e6fc;
}

.tag__closable-every-time button,
.tag__closable-every-time svg {
  width: 10px;
  height: 10px;
}

.tag__editable-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #675d8b;
}
</style>