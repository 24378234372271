export default {
  state: {
    searchProducts: null,
    products: [],
    loadingProducts: false,
    totalProducts: 0,
    nextProducts: null,
    getProductsTrigger: 0,
    imagePreviewTrigger: 0,
    widgetPreviewTrigger: 0,
    forceTableProductsUpdate: 0,
  },
  mutations: {
    setValueModule (state, keyValue) {
      // console.log('set value module render')
      if (keyValue.key in state) {
        state[keyValue.key] = keyValue.value
      }
    },
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    },
    deleteProductByID(state, productId) {
      state.products = state.products.filter((_product) => _product.id !== productId);
    },
    addProduct (state, product) {
      state.products.unshift(product)
    },
    updateProduct (state, product) {
      const productIndex = state.products.findIndex((_product) => _product.id === product.id)
      state.products[productIndex] = {...state.products[productIndex], ...product}
      state.forceTableProductsUpdate++
    }
  },
  actions: {
    SET_VALUE_MODULE ({commit}, keyValue) {
      commit('setValueModule', keyValue)
    },
    SET_VALUE ({ commit }, keyValue) {
      commit('setValue', keyValue)
    },
    DELETE_PRODUCT_BY_ID({ commit }, productId) {
      commit('deleteProductByID', productId)
    },
    ADD_PRODUCT ({ commit }, product) {
      commit('addProduct', product)
    },
    PUT_PRODUCT ({ commit, state }, product) {
      if (!product || !product.id) {
        console.warn('cancel put product. Product has no ID', product)
        return
      }
      const productExist = state.products.find((_product) => _product.id === product.id)
      if (productExist) {
        commit('updateProduct', product)
      } else {
        commit('addProduct', product)
      }
    }
  }
}
