<template>
  <v-app>
    <v-navigation-drawer
      v-if="
        isAuthorized &&
        route !== '/login' &&
        route !== '/confirm-email' &&
        routeName !== 'confirm'
      "
      left
      app
      floating
      :width="menuWidth"
      permanent
    >
      <NavigationVue @change-menu-width="menuWidth = $event" />
    </v-navigation-drawer>
    <v-main style="overflow-y: hidden">
      <v-container fluid class="pa-0 router-container">
        <keep-alive include="products-zarbo">
          <router-view></router-view>
        </keep-alive>
      </v-container>
    </v-main>

    <notifications
      group="foo"
      class="zarbo-notifications mb-1"
      :width="360"
      :duration="6000"
    >
      <template slot="body" slot-scope="props">
        <div class="zarbo-notifications__wrapper">
          <div class="zarbo-notifications__content">
            <div class="d-flex align-center justify-start">
              <v-icon
                v-if="props.item.type === 'success'"
                color="#27A37E"
                class="mr-4"
                size="24"
                >$vuetify.icons.success</v-icon
              >
              <v-icon
                v-if="props.item.type === 'error'"
                color="#FF6262"
                class="mr-4"
                size="24"
                >$vuetify.icons.error</v-icon
              >
              <div>
                <div class="d-flex align-center">
                  <h5 class="zarbo-notifications__title">
                    {{ props.item.title }}
                  </h5>
                  <v-btn
                    @click="props.close"
                    icon
                    class="zarbo-notifications__button-close"
                  >
                    <v-icon color="#675D8B" size="14"
                      >$vuetify.icons.closeRounded</v-icon
                    >
                  </v-btn>
                </div>
                <p
                  class="zarbo-notifications__text"
                  v-html="props.item.text"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </notifications>
  </v-app>
</template>

<script>
import NavigationVue from "./components/Navigation/NavigationVue.vue";
import { getYoutubePlaylist } from "@/components/service/api.js";

import { mapState } from "vuex";

export default {
  name: "App",
  data: () => ({
    refreshInterval: 4,
    refreshTimer: null,
    isConfirmEditCollection: false,
    isConfirmDeleteCollection: false,
    collectionToEdit: {},
    collectionToDelete: {},
    loadingEditCollection: false,
    loadingDeleteCollection: false,
    arrSpeed: [],
    count: 0,
    focusinput: false,
    menuWidth: "388px",
  }),
  computed: {
    ...mapState({
      initRoute: (state) => state.appStore.initRoute,
      collections: (state) => state.appStore.collections,
      isAuthorized: (state) => state.appStore.isAuthorized,
      userInfo: (state) => state.appStore.user,
    }),
    route() {
      return this.$route.path;
    },
    routeName() {
      return this.$route.name;
    },
  },
  watch: {
    isAuthorized(val) {
      if (val) {
        this.startRefreshTimer();
        this.getSubscription();
        this.getPlans();
        // Запрашиваем плейлист с обучением
        getYoutubePlaylist(this.$config).then((resp) => {
          this.setYoutubeFlag(resp)
        });
      } else {
        this.stopRefreshToken();
      }
    },
    searchProductsValue(newVal) {
      if (newVal === "") {
        this.$store.dispatch("SET_VALUE", {
          key: "searchProducts",
          value: null,
        });
      } else {
        this.$store.dispatch("SET_VALUE", {
          key: "searchProducts",
          value: newVal,
        });
      }
    },
  },
  mounted() {
    if (this.isAuthorized && this.refreshTimer === null) {
      this.startRefreshTimer();
      this.refreshTokenMix();
      this.getSubscription();
      this.getPlans();
      // Запрашиваем плейлист с обучением
      getYoutubePlaylist(this.$config).then((resp) => {
        this.setYoutubeFlag(resp)
      });
    }
    // Скачивание стилей
    var style = document.createElement("link");
    style.href = this.$config.ZARBO_VIEWER_STYLE;
    style.rel = "stylesheet";
    document.head.appendChild(style);

    if (localStorage.getItem("selectedTeam")) {
      this.$store.dispatch("SET_VALUE", {
        key: "selectedTeam",
        value: localStorage.getItem("selectedTeam"),
      });
    }
  },
  methods: {
    setYoutubeFlag (respData) {
      const lastOpenYoutubePlaylist = localStorage.getItem(
        "lastOpenYoutubePlaylist"
      );

      if (respData.items && respData.items.length !== 0) {
        if (lastOpenYoutubePlaylist) {
          respData.items.forEach((video) => {
            if (video.snippet.publishedAt > lastOpenYoutubePlaylist) {
              this.$store.dispatch("SET_VALUE", {
                key: "isNewVideoOnYoutubePlaylist",
                value: true,
              });
            }
          });
        } else {
          this.$store.dispatch("SET_VALUE", {
            key: "isNewVideoOnYoutubePlaylist",
            value: true,
          });
        }
      }
    },
    createProduct() {
      let value = this.$store.state.appStore.triggerCreateProduct;
      value++;
      this.$store.dispatch("SET_VALUE", {
        key: "triggerCreateProduct",
        value: value,
      });
    },

    searchProducts() {
      this.$store.dispatch("SET_VALUE", {
        key: "searchProductsForce",
        value: true,
      });
    },

    async editCollection(collection) {
      const url =
        this.$config.BASE_URL + "/api/v1/collections/" + collection.id + "/";
      try {
        this.loadingEditCollection = true;
        await this.$axios.patch(url, collection);
        this.isConfirmEditCollection = false;
        this.$notify({
          group: "foo",
          title: "Редактирование коллекции",
          text: "Коллекция <b>" + collection.name + "</b> успешно изменена",
          type: "success",
        });
        this.loadingEditCollection = false;
      } catch (error) {
        this.isConfirmEditCollection = false;

        this.handleErrorMix(error, true)
        // this.$notify({
        //   group: "foo",
        //   title: "Удаление коллекции",
        //   text: "Не удалось изменить коллекцию",
        //   type: "error",
        // });
        this.loadingEditCollection = false;
      }
    },
    startRefreshTimer() {
      this.refreshTimer = setInterval(() => {
        this.refreshTokenMix();
      }, this.refreshInterval * 1000 * 60);
    },
    stopRefreshToken() {
      clearInterval(this.refreshTimer);
    },
    async getPlans() {
      const urlPlans = this.$config.BASE_URL + "/api/v1/billing/plans";
      try {
        const resPlans = await this.$axios.get(urlPlans);
        this.$store.dispatch("SET_VALUE", {
          key: "plans",
          value: resPlans.data,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getSubscription() {
      let urlSubscription =
        this.$config.BASE_URL + "/api/v1/billing/subscription";
      if (localStorage.getItem("selectedTeam")) {
        urlSubscription = urlSubscription + '?team=' + localStorage.getItem("selectedTeam")
      }
      try {
        const resSubscription = await this.$axios.get(urlSubscription);
        this.$store.dispatch("SET_VALUE", {
          key: "subscription",
          value: resSubscription.data,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: { NavigationVue },
};
</script>

<style lang="scss">
.router-container {
  height: 100%;
  width: 100%;
}
.v-pagination__item--active {
  box-shadow: inherit !important;
}
.v-pagination__item {
  box-shadow: inherit !important;
  border: 1px solid #e0e0e0;
}
.v-pagination__navigation {
  box-shadow: inherit !important;
  border: 1px solid #e0e0e0;
}
.admin-menu {
  box-shadow: none !important;
  background: #4e4087 !important;
  border-radius: 0 0 10px 10px !important;
  overflow: hidden;
  hr {
    display: none;
  }
  .v-list {
    background: #4e4087 !important;
    border-radius: 10px;
    padding: 0;
  }
  .v-list-item__title {
    color: white;
    font-weight: 400;
    font-size: 16px;
  }
  .v-list-item {
    &:hover {
      background: #12133a;
    }
    &::before {
      opacity: 0 !important;
      &:hover {
        opacity: 0 !important;
      }
      &:active {
        opacity: 0 !important;
      }
    }
  }
}
.admin-btn {
  width: auto !important;
  span {
    text-transform: none;
    color: #ffffff !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
  &::before {
    opacity: 0 !important;
    &:hover {
      opacity: 0 !important;
    }
    &:active {
      opacity: 0 !important;
    }
  }
}

.v-card__title {
  word-break: break-word;
}
.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}
.logo {
  max-height: 38px; // новогоднее лого
}

.gradient-button {
  box-sizing: border-box;
  // width: 190px;
  padding: 0 15px;
  height: 40px;
  background-color: #232451; // primary
  color: white !important;
  position: relative;
  border-radius: 10px;
  border: none;
  overflow: hidden;
  font-size: 16px;
}

.gradient-button::before {
  box-sizing: border-box;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: inherit;
  height: inherit;
  border: 5px solid;
  border-radius: 10px;
  border-top-width: 0;
  border-bottom-width: 0;
  border-image: linear-gradient(0deg, #e94309, #e92a6e, #a204e8) 1;
  // z-index: -1;
}
.block-btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0; /*Необязательно, но мб надо будет*/
  width: fit-content;
}
.mybtn {
  transform-style: preserve-3d;
  padding: 11px 10px;
  outline: none;
  border: none;
  width: calc(100% + 10px);
  background: #232451;
  border: 2px solid #232451;
  box-sizing: border-box;
  border-radius: 10px;
  flex: none;

  display: flex;
  align-items: center;
  justify-content: center;

  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}
.mybtn__size {
  padding-left: 21px !important;
  padding-right: 21px !important;
}
.mybtn::before {
  content: "";
  display: block;
  position: absolute;
  width: calc(100% + 12px);
  height: calc(100% + 4px);
  background: linear-gradient(
    180deg,
    #e94309 2.42%,
    #e92a6e 36.36%,
    #a204e8 73.08%
  );
  border-radius: 12px;
  top: -2px;
  z-index: -1;
}
.mybtn::after {
  background: #232451;
  content: "";
  display: block;
  position: absolute;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border-radius: 12px;
  z-index: -1;
}

.mybtn:hover {
  width: calc(100% + 1px);
  transition: all 0.3s;
}

.mybtn:hover::before {
  width: calc(100% + 21px);
  transition: all 0.3s;
}
.mybtn:active {
  width: 100%;
  transition: all 0.3s;
}
.mybtn:active::before {
  width: calc(100% + 10px);
  transition: all 0.3s;
}
.mybtn:disabled {
  height: 43px;
  border: 0;
  background: #9191a8;
}
.mybtn:disabled::before {
  top: 0;
  height: 100%;
  background: linear-gradient(
    159.37deg,
    #f4a184 2.42%,
    #f394b8 36.36%,
    #d081f3 73.08%
  );
}
.mybtn:disabled:hover {
  width: calc(100% + 12px);
  transition: none;
}
.mybtn:disabled:hover::before {
  width: calc(100% + 10px);
  transition: none;
}

.card-full-height {
  height: calc(100vh - 113px);
  min-height: calc(100vh - 114px);
  max-height: calc(100vh - 112px);
  .v-card__text {
    height: calc(100% - 130px);
  }
}

.table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  &__buttons {
    display: flex;
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__right {
    display: flex;
    align-items: center;
    &:nth-child(3) {
      margin-right: 800px;
    }
  }
  .total-item {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    text-decoration: underline;
    &.active {
      color: #000 !important;
      text-decoration: none;
    }
  }
}
zarbo-widget #qr-button::before {
  box-sizing: content-box;
}
.custom-icon {
  fill: currentColor;
}
// Scroll for Chrome, Edge и Safari
* {
  scrollbar-width: thin;
}
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  // background: #f0f0f0;
  background: white !important;
  // margin-block: -10px;
  border-radius: 4px;
}
*::-webkit-scrollbar-thumb {
  width: 8px;
  background: #dddcff;
  border-radius: 4px;
}
// Scroll for Firefox
* {
  scrollbar-width: thin;
  scrollbar-color: #dddcff white;
}
// Цвет букв внутри v-select & etc...
.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  color: #4e4087 !important;
}
.icon-block__input {
  border-radius: 10px !important;
  max-width: 576px !important;
  fieldset {
    border: 1px solid #f0f0f0 !important ;
  }
}
.email-support {
  position: absolute;
  bottom: 5px;
  right: 10px;
}
.footer {
  @include media("max", "576") {
    font-size: 14px;
  }
}
</style>
