export default {
  state: {
    isAuthorized: false,
    selectedCollection: [],
    collections: [],
    qualities: [],
    user: null,
    subscription: null,
    plans: null,
    initRoute: null,
    email: null,
    isRegistered: false,
    selectedTeam: {},
    widgetId: null,
    searchProducts: null,
    myTeam: null,
    products: [],
    lastLocation: null,
    selectedTabLast: null,
    userRegData: {},
    selectedMenuUser: 0,
    allTeams: [],
    isNewVideoOnYoutubePlaylist: false,
    isNewSocialConnected: false,
  },
  mutations: {
    setValueModule (state, keyValue) {
      // console.log('set value module render')
      if (keyValue.key in state) {
        state[keyValue.key] = keyValue.value
      }
    },
    setValue(state, keyValue) {
      state[keyValue.key] = keyValue.value;
    },
    DECREMENT_COUNT(state, collectionId) {
      const collection = state.collections.find(
        (item) => item.id === collectionId
      );
      if (collection) {
        collection.count -= 1;
      }
    },
  },
  actions: {
    SET_VALUE_MODULE ({commit}, keyValue) {
      commit('setValueModule', keyValue)
    },
    SET_VALUE({ commit }, keyValue) {
      commit("setValue", keyValue);
    },
    decrementCountInCollection({ commit }, product) {
      commit("DECREMENT_COUNT", product.collection_id);
    },
  },
};
